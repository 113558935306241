import {Injectable} from '@angular/core';
import {coerceBoolean, uniq} from '@matchsource/utils';
import {SourceType} from '@matchsource/models/source';
import {Observable, of} from 'rxjs';
import {
  MatchResultControllerService,
  MatchCriteriaDto,
  PageMatchResultDto,
  MatchResultDto,
} from '@matchsource/api-generated/search-match';
import flow from 'lodash-es/flow';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import {HttpContext} from '@angular/common/http';
import {ACTUAL_PHENOTYPE_INDEX} from '@matchsource/models/patient-shared';
import {silentError, useSpinner} from '@matchsource/core';

@Injectable({
  providedIn: 'root',
})
export class MatchResultsApiService {
  constructor(private readonly matchResultControllerService: MatchResultControllerService) {}

  private static getLoadingCustomErrorHandling(): (context?: HttpContext) => HttpContext {
    return setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingSourceData);
  }

  search(
    searchParams: MatchCriteriaDto,
    options: {silent?: boolean; skipSpinner?: boolean} = {silent: false, skipSpinner: true}
  ): Observable<PageMatchResultDto> {
    const customErrorHandlingContext = MatchResultsApiService.getLoadingCustomErrorHandling();
    const context = flow(
      customErrorHandlingContext,
      useSpinner(options.skipSpinner),
      silentError(coerceBoolean(options.silent))
    );

    return this.matchResultControllerService.getMatchResults(
      {
        body: searchParams,
      },
      {
        context: context(),
      }
    );
  }

  getSources(
    patientId: MsApp.Guid,
    sourceIds: MsApp.Guid[],
    sourceType: SourceType
  ): Observable<{
    [key: string]: MatchResultDto;
  }> {
    if (sourceIds.length === 0) {
      return of({});
    }

    const context = MatchResultsApiService.getLoadingCustomErrorHandling();

    return this.matchResultControllerService.getComputedMatchResult(
      {
        body: {
          recipientGuid: patientId,
          sources: uniq(sourceIds).map(id => ({guid: id, sourceType})),
          seqNum: ACTUAL_PHENOTYPE_INDEX,
        },
      },
      {context: context()}
    );
  }
}
